<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ doc.name }}
    </h5>

    <div class="buttons is-pulled-rght">
      <button
        class="button is-danger"
        @click="removePart"
      >
        <b-icon
          icon="delete"
        />
        <span>Delete part</span>
      </button>
    </div>

    <a-input
      field="name"
      type="text"
    />

    <a-input
      field="description"
      type="textarea"
    />

    <a-select
      field="supplier"
      options="suppliers"
    />
  </div>
</template>

<script>
import formMixin from './formMixin'

export default {
  mixins: [formMixin],
  methods: {
    removePart () {
      const self = this
      this.$buefy.dialog.confirm({
        message: `This will delete part permanently!`,
        position: 'is-bottom',
        type: 'is-danger',
        cancelText: 'Cancel',
        confirmText: 'OK',
        onConfirm: () => {
          self.$store.dispatch(`parts/delete`, self.doc.id)
          self.$buefy.toast.open('Part deleted')
          self.$router.push({ name: 'parts' })
        }
      })
    }
  }
}
</script>
